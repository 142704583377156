import { AllowedAttachmentType, AppRoutes } from "@amenda-types";
import { CollectionDropdown, ResourceCounter } from "@amenda-components/Shared";
import { FC, useCallback } from "react";
import { HelperMessage, Link, NavigationFilter } from "@amenda-components/App";
import {
  LayoutTypes,
  isSearchFilterEmpty,
} from "@amenda-components/Shared/common";
import {
  useAttachmentStore,
  useProjectStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { AvailableForms } from "@amenda-constants";
import { ConstructionDetailsActionBar } from "./ConstructionDetailsActionBar";
import { ConstructionDetailsGridView } from "./ConstructionDetailsGridView";
import { ConstructionDetailsProps } from "./types";
import { ConstructionDetailsTable } from "./ConstructionDetailsTable";
import { ConstructionIcon } from "lucide-react";
import { FormAutoSaveSubmitProps } from "@amenda-components/PageBuilder";
import { getComponentsFromForms } from "@amenda-utils";
import isEmpty from "lodash/isEmpty";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ConstructionDetails: FC<ConstructionDetailsProps> = ({
  rootRoute,
  attachments,
  canUpload,
  canCreateCollection,
  pagination,
  collectionType,
  isCollection,
  label = "Construction Details",
  searchAttachments,
  fetchAttachments,
  sortAttachments,
  fetchPaginatedAttachments,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const clearSelectedAttachments = useAttachmentStore(
    (state) => state.clearSelectedAttachments,
  );
  const layoutView = useAttachmentStore((state) => state.layoutView);
  const {
    searchTerm,
    sidebarFilters,
    setSearchTerm,
    setSidebarFilters,
    clearSidebarFilters,
  } = useSidebarFiltersWithPath();
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const selectAllAttachments = useAttachmentStore(
    (state) => state.setSelectAllAttachments,
  );

  const { filteredDocsCount, docsCount = 0 } = pagination ?? {};
  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);

  const handleCreateNew = () => {
    navigate(AppRoutes.ConstructionDetailsNew);
  };

  const handleSearch = async ({ sanitizedData }: FormAutoSaveSubmitProps) => {
    const { searchTerm, ...rest } = sanitizedData;
    if (!searchAttachments) return;
    setSearchTerm(searchTerm);
    setSidebarFilters(rest);
    await searchAttachments(searchTerm, rest);
  };

  const handleClearSearch = async () => {
    if (!searchAttachments) return;
    clearSidebarFilters();
    await searchAttachments("", {});
  };

  const handleResourceIds = async (args: Record<string, any>) => {
    let attachmentIds: string[] = [];

    if (fetchAttachments) {
      const attachments = await fetchAttachments({
        formValues: args,
        attachmentType: AllowedAttachmentType.pdf,
        callback: (data: any) => {},
      });

      attachmentIds =
        attachments?.map((attachment: any) => attachment.id) ?? [];
    }
    return attachmentIds;
  };

  const handleGetNextPage = useCallback(async () => {
    if (fetchPaginatedAttachments) {
      await fetchPaginatedAttachments();
    }
  }, [fetchPaginatedAttachments]);

  return (
    <>
      <div className="sticky top-0 z-30 w-full bg-white pt-4 lg:pt-0">
        {Boolean(canCreateCollection) ? (
          <CollectionDropdown
            label={label}
            goBackLabel="All construction details"
            resourceIds={selectedAttachments}
            collectionType={collectionType}
            routes={{
              goBack: AppRoutes.ConstructionDetails,
              collection: AppRoutes.ConstructionDetailsCollection,
            }}
            getResourceIds={handleResourceIds}
            clearSelectedResources={clearSelectedAttachments}
          />
        ) : (
          <h3 className="amenda-form-heading pt-5">{t(label)}</h3>
        )}
        <NavigationFilter
          className="pb-4 pt-7"
          searchTerm={searchTerm}
          sidebarFilters={sidebarFilters}
          components={components}
          searchPlaceholder="Search construction details"
          handleSearch={handleSearch}
          handleClearSearch={handleClearSearch}
        >
          <ConstructionDetailsActionBar
            canUpload={canUpload}
            isCollection={isCollection}
            collectionType={collectionType}
            canCreateCollection={canCreateCollection}
            fetchAttachments={fetchAttachments}
          />
        </NavigationFilter>
        <div className="flex h-6 w-full items-center space-x-3">
          <ResourceCounter
            currentCount={attachments.length}
            totalCount={filteredDocsCount ?? docsCount}
            hasSearch={
              !isEmpty(searchTerm) || !isSearchFilterEmpty(sidebarFilters)
            }
          />
          {Number(selectedAttachments.length) > 0 && (
            <div className="flex flex-row items-center space-x-3">
              <Link onClick={clearSelectedAttachments}>
                <span className="amenda-component-label text-sm">
                  {t("Deselect All")} ({selectedAttachments.length})
                </span>
              </Link>
              <Link onClick={selectAllAttachments}>
                <span>{t("Select All")}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
      {layoutView === LayoutTypes.Grid &&
        !isSearching &&
        !isFetching &&
        isEmpty(attachments) && (
          <>
            {!isEmpty(pagination) &&
            !Boolean(pagination?.hasNext) &&
            !Boolean(pagination?.hasPrevious) &&
            isEmpty(searchTerm) &&
            isSearchFilterEmpty(sidebarFilters) ? (
              <HelperMessage
                actionText={canUpload ? "Add new detail" : undefined}
                Icon={ConstructionIcon}
                message="No Construction details found"
                helpText="Please add construction details to view them here"
                onClick={canUpload ? handleCreateNew : undefined}
              />
            ) : (
              <HelperMessage
                Icon={ConstructionIcon}
                message="No Construction details found"
                helpText="Try changing your search filters"
              />
            )}
          </>
        )}
      {layoutView === LayoutTypes.Grid && (
        <ConstructionDetailsGridView
          rootRoute={rootRoute}
          attachments={attachments}
          pagination={pagination}
          fetchPaginatedAttachments={handleGetNextPage}
        />
      )}
      {layoutView === LayoutTypes.Table && (
        <ConstructionDetailsTable
          rootRoute={rootRoute}
          pagination={pagination}
          attachments={attachments}
          isCollection={isCollection}
          collectionType={collectionType}
          sortAttachments={sortAttachments}
          fetchPaginatedAttachments={handleGetNextPage}
        />
      )}
    </>
  );
};
