import { AllowedAttachmentType, ReactTableKeys } from "@amenda-types";
import { AvailableForms, FormComponentTypes } from "@amenda-constants";
import { ChangeEvent, FC } from "react";
import { getComponentsById, getComponentsFromForms } from "@amenda-utils";
import {
  useAttachmentStore,
  useFormStore,
  useProjectStore,
  useSettingsStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { ConstructionDetailsProps } from "./types";
import { Image } from "@amenda-components/App";
import { ReactTable } from "@amenda-components/Shared";
import { TableCellWrapper } from "@amenda-components/Shared/ReactTableComponents";
import { dynamicTableColumns } from "@amenda-components/PageBuilder/dynamicTableColumns";
import { transformFilters } from "@amenda-components/Shared/common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

type Props = Pick<
  ConstructionDetailsProps,
  | "attachments"
  | "rootRoute"
  | "pagination"
  | "fetchPaginatedAttachments"
  | "sortAttachments"
  | "isCollection"
  | "collectionType"
>;

const ConstructionFile: FC<{
  src: string;
}> = ({ src }) => {
  const selectedImageVariant = useAttachmentStore(
    (state) => state.selectedImageVariant,
  );

  return (
    <div className="w-full">
      <Image
        background="bg-transparent"
        magicSize="gallery"
        url={src}
        variant={selectedImageVariant}
      />
    </div>
  );
};

export const ConstructionDetailsTable: FC<Props> = ({
  rootRoute,
  pagination,
  attachments,
  isCollection,
  collectionType,
  sortAttachments,
  fetchPaginatedAttachments,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const forms = useProjectStore((state) => state.forms);
  const selectedAttachments = useAttachmentStore(
    (state) => state.selectedAttachments,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const setSelectedAttachment = useAttachmentStore(
    (state) => state.setSelectedAttachment,
  );
  const setSelectedAttachments = useAttachmentStore(
    (state) => state.setSelectedAttachments,
  );
  const toggleSelectedAttachment = useAttachmentStore(
    (state) => state.toggleSelectedAttachment,
  );
  const isSearching = useAttachmentStore((state) => state.isSearching);
  const isFetching = useAttachmentStore((state) => state.isFetching);
  const { sidebarFilters } = useSidebarFiltersWithPath();
  const selectedCollectionByType = useFormStore(
    (state) => state.selectedCollectionByType,
  );

  const selectedCollection = selectedCollectionByType[collectionType];
  const availableForms = forms?.[AvailableForms.ConstructionDetails];
  const components = getComponentsFromForms(availableForms);
  const componentsById = getComponentsById(components);
  const dynamicColumnIds = components
    .filter(
      (c) =>
        Boolean(c.component) &&
        ![
          FormComponentTypes.Save,
          FormComponentTypes.Image,
          FormComponentTypes.Hidden,
          FormComponentTypes.Title,
        ].includes(c.component),
    )
    .map((c) => c.id);

  const handleClick =
    (attachment: any) => (e: ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      setSelectedAttachment(attachment);
      navigate(`${rootRoute}/${attachment.id}`);
    };

  return (
    <ReactTable
      showSelectorColumn
      isFullWidth
      isConfigurable
      canDownload
      isResizable={true}
      data={attachments}
      pagination={pagination}
      disableScrollTo={false}
      isLoading={isSearching || isFetching}
      maxEstimatedRowHeight={80}
      tableId={ReactTableKeys.ConstructionDetails}
      containerClass="h-[calc(100vh-16rem)] w-full"
      selectedRowIds={selectedAttachments}
      downloadProps={{
        ids: isCollection ? selectedCollection?.resourceIds : undefined,
        filters: {
          ...transformFilters(sidebarFilters),
          type: AllowedAttachmentType.pdf,
        },
      }}
      handleToggleRowSelection={toggleSelectedAttachment}
      handleToggleRowsSelection={setSelectedAttachments}
      onRowClick={handleClick}
      fetchNextPage={fetchPaginatedAttachments}
      handleFilter={sortAttachments}
      columns={[
        {
          id: "gallery",
          minSize: 20,
          meta: {
            isPhotoUrl: true,
            label: t("File"),
          },
          cell: ({ row }) => {
            const attachment = row?.original;

            return (
              <TableCellWrapper>
                <ConstructionFile src={attachment?.url} />
              </TableCellWrapper>
            );
          },
        },
        ...dynamicTableColumns({
          permissions,
          componentsById,
          dynamicColumnIds,
          flattenData: (data = {}) => {
            return {
              ...data,
              ...(data?.formValues || {}),
            };
          },
        }),
      ]}
    />
  );
};
