import { Cell, Header, Table } from "@tanstack/react-table";

import { formatDate } from "@amenda-utils";

export enum SpecialColumns {
  SELECT = "select",
  ACTIONS = "userActions",
  GROUPING = "grouping",
}

export const isLastHeader = (index: number, headers: any[]) => {
  return index + 1 === headers.length;
};

const getStickyPosition = (
  items: Header<any, unknown>[] | Cell<any, unknown>[],
  i: number,
) => {
  const prevItems = items.slice(0, i);
  const currItem = items[i];
  let left = 0;
  prevItems.forEach((item) => {
    if (item.column.getIsPinned()) {
      left += item.column.getSize();
    }
  });

  if (currItem.column.id === SpecialColumns.ACTIONS) {
    return {
      right: 0,
    };
  }
  return {
    left,
  };
};

export const getHeaderStickyPosition = (
  headers: Header<any, unknown>[],
  index: number,
) => {
  const currHeader = headers[index];
  if (currHeader.column.getIsPinned()) {
    return getStickyPosition(headers, index);
  }
  return {};
};

export const getCellStickyPosition = (
  cells: Cell<any, unknown>[],
  index: number,
) => {
  const currCell = cells[index];
  if (currCell.column.getIsPinned()) {
    return getStickyPosition(cells, index);
  }
  return {};
};

const getPinnedClassName = (
  items: Cell<any, unknown>[] | Header<any, unknown>[],
  i: number,
) => {
  let pinnedItem: Cell<any, unknown> | Header<any, unknown> | undefined;

  items.forEach((item) => {
    if (
      item.column.getIsPinned() &&
      item.column.id !== SpecialColumns.ACTIONS
    ) {
      pinnedItem = item;
    }
  });

  const currItem = items[i];
  if (pinnedItem && pinnedItem.column.id === currItem.column.id) {
    return "border-r";
  } else if (currItem.column.id === SpecialColumns.ACTIONS) {
    return "border-l";
  }
  return "";
};

export const getPinnedCellClassName = (
  cells: Cell<any, unknown>[],
  index: number,
) => {
  return getPinnedClassName(cells, index);
};

export const getPinnedHeaderClassName = (
  headers: Header<any, unknown>[],
  index: number,
) => {
  return getPinnedClassName(headers, index);
};

export const isSelectColumn = (id: string) =>
  [SpecialColumns.SELECT, SpecialColumns.ACTIONS].includes(
    id as SpecialColumns,
  );

export const getPadding = (virtualRows: any[], totalSize: number) => {
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  return {
    paddingBottom,
    paddingTop: virtualRows?.[0]?.start || 0,
  };
};

export const getTableSize = (table: Table<any>, isFullWidth: boolean) => {
  const width = isFullWidth ? "100%" : table.getCenterTotalSize();

  return {
    width,
  };
};

export const getDefaultPinnedColumns = ({
  showSelectorColumn,
  showUserActionColumn,
  defaultPinnedColumns,
  showGroupingColumn,
}: {
  showGroupingColumn: boolean;
  showSelectorColumn: boolean;
  showUserActionColumn: boolean;
  defaultPinnedColumns?: { left?: string[]; right?: string[] };
}) => {
  let left = showGroupingColumn
    ? [SpecialColumns.GROUPING, ...(defaultPinnedColumns?.left || [])]
    : [...(defaultPinnedColumns?.left || [])];
  const right = showUserActionColumn
    ? [SpecialColumns.ACTIONS, ...(defaultPinnedColumns?.right || [])]
    : [...(defaultPinnedColumns?.right || [])];

  if (showSelectorColumn) {
    left = [SpecialColumns.SELECT, ...left];
  }

  return {
    left,
    right,
  };
};

//TODO: investigate this line causing ReferenceError
export const getFormat = () => formatDate;
