import {
  AllowedAttachmentType,
  AllowedCollectionType,
  AppRoutes,
} from "@amenda-types";
import { FC, ReactNode, useEffect } from "react";
import {
  useAttachmentStore,
  useSidebarFiltersWithPath,
} from "@amenda-domains/mutations";

import { MediaGallery } from "@amenda-components/Gallery";
import { MediaGalleryProps } from "@amenda-components/Gallery/common";
import isEmpty from "lodash/isEmpty";
import { transformFilters } from "@amenda-components/Shared/common";
import { useLocation } from "react-router-dom";
import { useRunOnMountOnly } from "@amenda-utils";
import { useSearchAttachments } from "@amenda-domains/queries";

interface Props {
  projectId?: string;
}

interface ProjectMediaWrapperProps extends Props {
  children: (
    props: Pick<MediaGalleryProps, "attachments" | "searchAttachments">,
  ) => ReactNode;
}

export const ProjectMediaWrapper: FC<ProjectMediaWrapperProps> = ({
  projectId,
  children,
}) => {
  const { pathname, search } = useLocation();
  const { searchAttachments } = useSearchAttachments();
  const attachments = useAttachmentStore((state) => state.attachments);
  const setIsSearching = useAttachmentStore((state) => state.setIsSearching);
  const setAttachments = useAttachmentStore((state) => state.setAttachments);
  const { sidebarFilters, searchTerm } = useSidebarFiltersWithPath();
  const setAttachmentProject = useAttachmentStore(
    (state) => state.setAttachmentProject,
  );

  const handleSearchAttachments = async (searchTerm: string) => {
    if (!isEmpty(searchTerm) && projectId) {
      setIsSearching(true);
      setAttachments({
        attachments: [],
      });
      await searchAttachments({
        searchTerm,
        filters: {
          ...transformFilters(sidebarFilters),
          "formValues.project": projectId,
          type: AllowedAttachmentType.image,
        },
      });
      setIsSearching(false);
    }
  };

  useEffect(() => {
    const searchAttachmentAsync = async () => {
      if (projectId && isEmpty(searchTerm)) {
        setIsSearching(true);
        setAttachments({
          attachments: [],
        });
        await searchAttachments({
          filters: {
            ...transformFilters(sidebarFilters),
            "formValues.project": projectId,
            type: AllowedAttachmentType.image,
          },
        });
        setIsSearching(false);
      }
    };

    searchAttachmentAsync();
  }, [
    projectId,
    sidebarFilters,
    searchTerm,
    setIsSearching,
    setAttachments,
    searchAttachments,
  ]);

  useRunOnMountOnly(
    () => {
      setAttachmentProject({
        projectId,
        goBackRoute: pathname + (search ?? ""),
      });
    },
    JSON.stringify([projectId, pathname, search]),
  );

  return (
    <div className="flex h-full flex-col">
      {children({
        attachments,
        searchAttachments: handleSearchAttachments,
      })}
    </div>
  );
};

export const EditProjectMedia: FC<Props> = ({ projectId }) => {
  if (!projectId) return null;
  return (
    <ProjectMediaWrapper projectId={projectId}>
      {({ attachments, searchAttachments }) => (
        <MediaGallery
          canUpload={true}
          canSetSlider={true}
          label="Media"
          attachments={attachments}
          rootRoute={AppRoutes.Attachments}
          collectionType={AllowedCollectionType.Attachments}
          searchAttachments={searchAttachments}
        />
      )}
    </ProjectMediaWrapper>
  );
};
