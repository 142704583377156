import {
  AllowedCollectionType,
  GeneralPermissionTypes,
  PageComponentProps,
  Pagination,
} from "@amenda-types";
import {
  getComponentsById,
  sanitizeData,
  sanitizeNestedData,
} from "@amenda-utils";
import { isEmpty, isNil, sortBy, uniqBy } from "lodash";

import { FormComponentTypes } from "@amenda-constants";
import { processComponentPermissions } from "@amenda-components/Shared/common";

const getUniqOnly = (addItems: any[], existingItems: any[]) => {
  return uniqBy([...existingItems, ...addItems], (item) => item.id || item);
};

const keywordsAndCount = (
  keywords: string[],
  values: Record<string, number> = {},
) => {
  const count = { ...values };

  keywords.forEach((keyword) => {
    count[keyword] = (count[keyword] || 0) + 1;
  });
  return count;
};

const getFromData = (
  data: Record<string, Record<string, number>>,
  expectedTotal: number,
) => {
  const values: Record<string, any[]> = {};

  Object.keys(data).forEach((dataKey) => {
    const value = data[dataKey];

    Object.keys(value).forEach((key) => {
      if (value[key] === expectedTotal) {
        values[dataKey] = [...(values[dataKey] || []), key];
      }
    });
  });
  return values;
};

export const getSharedKeywordsFromAttachments = (
  attachments: any[] = [],
  availableComponents: any[],
) => {
  const values: Record<string, Record<string, number>> = {};
  const formValues = attachments
    .filter((a) => Boolean(a?.formValues))
    .map((a) => a.formValues);
  const keywordsComponentIds: string[] = [];

  availableComponents.forEach((component) => {
    if (component.component === FormComponentTypes.Keyword) {
      keywordsComponentIds.push(component.id);
    }
  });

  formValues.forEach((formValue) => {
    keywordsComponentIds.forEach((id) => {
      if (formValue[id]) {
        values[id] = keywordsAndCount(formValue[id], values[id]);
      }
    });
  });

  return getFromData(values, attachments.length);
};

export const transformAttachmentToForm = (data: Record<string, any>) => {
  return sanitizeData(data);
};

export const transformFormToAttachmentBase = ({
  name,
  ...rest
}: Record<string, any> = {}) => {
  const input: any = {
    name,
    formValues: {
      name,
      ...rest,
    },
  };
  return sanitizeNestedData(input);
};

const getValuesToPull = (data: any[], existingData?: any[]) => {
  let valuesToPull: any[] = [];
  if (!existingData) return valuesToPull;

  existingData.forEach((d) => {
    if (!!d && !data.includes(d)) {
      valuesToPull.push(d);
    }
  });

  return valuesToPull;
};

const isComponentValuesArray = (component?: PageComponentProps) => {
  if (!component) return false;
  return (
    [FormComponentTypes.Keyword, FormComponentTypes.MultiSelect].includes(
      component.component as FormComponentTypes,
    ) ||
    (component.component === FormComponentTypes.SearchAndSelect &&
      Boolean(component.properties?.isMulti)) ||
    (component.component === FormComponentTypes.Badges &&
      !!component.properties?.isMulti)
  );
};

export const transformBulkEditorAttachmentsForm = ({
  data,
  values,
  components,
}: {
  data: Record<string, any>;
  values: Record<string, any>;
  components: PageComponentProps[];
}) => {
  const componentsById = getComponentsById(components);
  let transformedValues: Record<string, any> = {
    $addToSet: {},
    $pull: {},
  };

  Object.keys(data).forEach((key) => {
    const component = componentsById[key];

    if (isComponentValuesArray(component)) {
      const valuesToPull = getValuesToPull(data[key], values[key]);

      transformedValues.$addToSet[key] = { $each: data[key] };
      if (!isEmpty(valuesToPull)) {
        transformedValues.$pull[key] = { $in: valuesToPull };
      }
    } else {
      transformedValues[key] = data[key];
    }
  });

  if (isEmpty(transformedValues.$addToSet)) {
    delete transformedValues.$addToSet;
  }
  if (isEmpty(transformedValues.$pull)) {
    delete transformedValues.$pull;
  }

  return transformedValues;
};

export const transformFormToAttachment = ({
  defaultShareType,
  form = {},
  resourceId,
}: {
  defaultShareType?: string;
  form?: Record<string, any>;
  resourceId?: string;
}) => {
  const input: any = {
    _id: resourceId,
    ...transformFormToAttachmentBase(form),
  };

  if (defaultShareType && !resourceId) {
    input.share = {
      type: defaultShareType,
    };
  }

  return sanitizeData(input);
};

export const tranformUpdatedFormToAttachment = ({
  data,
  existingValues,
  generalValues,
}: {
  data: any;
  existingValues: any;
  generalValues: any;
}) => {
  let updatedValues: any = {};
  if (isEmpty(data)) {
    Object.keys(generalValues).forEach((key) => {
      if (!isEmpty(generalValues[key])) {
        updatedValues[key] = Array.isArray(generalValues[key])
          ? getUniqOnly(generalValues[key], existingValues[key] || [])
          : generalValues[key];
      }
    });
  } else {
    Object.keys({
      ...(generalValues || {}),
      ...(data || {}),
    }).forEach((key) => {
      if (isNil(data[key])) {
        updatedValues[key] = Array.isArray(generalValues[key])
          ? getUniqOnly(generalValues[key], existingValues[key] || [])
          : generalValues[key];
      } else {
        updatedValues[key] = data[key];
      }
    });
  }

  return updatedValues;
};

export const staticGalleryLabels = {
  reference: {
    label: "Reference",
    value: "reference",
  },
  activity: {
    label: "Activity",
    value: "activity",
  },
};

export const getStaticGalleryTabs = (mediaGeneralPermissions: any) => {
  return Object.keys(staticGalleryLabels)
    .map((key) => {
      return staticGalleryLabels[key as keyof typeof staticGalleryLabels];
    })
    .filter(({ value }) => {
      if (value === staticGalleryLabels.activity.value) {
        return Boolean(
          mediaGeneralPermissions[GeneralPermissionTypes.Activity],
        );
      }
      return true;
    });
};

export const getGalleryCardKeywordIds = ({
  components,
  permissions,
  attachment,
}: {
  components: any[];
  attachment: any;
  permissions: any;
}) => {
  const keywordComponentIds = components
    .filter((c) => c.component === FormComponentTypes.Keyword)
    .filter((c) =>
      processComponentPermissions({
        permissions,
        component: c,
      }),
    )
    .map((c) => c.id);

  let keywordIds: any[] = [];
  keywordComponentIds.forEach((id) => {
    const ids = attachment?.formValues?.[id];

    if (Array.isArray(ids)) {
      keywordIds = [...keywordIds, ...ids];
    }
  });
  return { keywordIds, keywordComponentIds };
};

export const getKeywordsFromSidebarFilters = (
  sidebarFilters: any,
  keywordComponentIds: string[],
) => {
  const keywords: string[] = [];

  keywordComponentIds.forEach((id) => {
    const values = sidebarFilters?.[id]?.value;
    if (values) {
      keywords.push(...values);
    }
  });
  return keywords;
};

export interface MediaGalleryProps {
  attachments: any[];
  isCollection?: boolean;
  collectionType: AllowedCollectionType;
  label?: string;
  pagination?: Pagination;
  canUpload?: boolean;
  canAssignAttachments?: boolean;
  canCreateCollection?: boolean;
  canSetSlider?: boolean;
  rootRoute: string;
  sortAttachments?: (columnSorting: any) => Promise<void>;
  fetchPaginatedAttachments?: () => Promise<void>;
  fetchAttachments?: (formValues: Record<string, any>) => Promise<any[]>;
  searchAttachments?: (searchTerm: string) => Promise<void>;
}

type GetSliderArgs = {
  attachments: any[];
  galleryUrl?: string;
  selectedAttachments: string[];
};

const isSliderAttachment = (
  attachment: any,
  { galleryUrl, selectedAttachments }: Omit<GetSliderArgs, "attachments">,
) => {
  if (Boolean(attachment?.formValues?.slider?.show)) {
    return true;
  }
  if (selectedAttachments.includes(attachment.id)) {
    return true;
  }
  if (galleryUrl && attachment.url === galleryUrl) {
    return true;
  }
  return false;
};

export const getSliderAttachments = ({
  attachments,
  galleryUrl,
  selectedAttachments,
}: GetSliderArgs) => {
  const sliderAttachments: any[] = [];

  attachments.forEach((a) => {
    if (isSliderAttachment(a, { galleryUrl, selectedAttachments })) {
      sliderAttachments.push(a);
    }
  });

  return sortBy(sliderAttachments, [
    function (a) {
      return a.formValues?.slider?.order;
    },
  ]);
};

export const isOriginalDownloadFormat = (downloadFormat: any = {}) => {
  return (
    downloadFormat.name.toLowerCase() === "original" &&
    downloadFormat.dimensions.width === 0 &&
    downloadFormat.dimensions.height === 0
  );
};
