import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  AllowedCollectionType,
  AllowedDownloadOutput,
  FormTab,
  ReactTableKeys,
} from "@amenda-types";
import {
  ArrowDown01Icon,
  ArrowDown10Icon,
  ArrowDownAZIcon,
  ArrowDownZAIcon,
  CloudDownloadIcon,
  Eye,
  EyeOff,
  GripVerticalIcon,
  XIcon,
} from "lucide-react";
import { Button, IconButtonBase, Link } from "@amenda-components/App";
import {
  ChangeEvent,
  FC,
  HTMLProps,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ChevronRightIcon,
  MousePointerIcon,
  PinIcon,
  Settings2Icon,
} from "lucide-react";
import { Column, ColumnDef, Row, Table } from "@tanstack/react-table";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
  FormBuilderSortableItem,
  isDragValid,
} from "@amenda-components/Settings/FormBuilder/FormBuilderDndComponents";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  generateColumnSorting,
  openUrlInNewTab,
  sanitizeNestedData,
} from "@amenda-utils";
import { useAppStore, useProjectStore } from "@amenda-domains/mutations";

import Fuse from "fuse.js";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { SpecialColumns } from "./reactTableHelpers";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { iconButtonTheme } from "@amenda-styles/theme";
import isNil from "lodash/isNil";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useDownload } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";

interface TableSortIndicatorsProps {
  tableId: ReactTableKeys;
  column: Column<any, any>;
  handleFilter?: (columnSorting: any) => void;
}

interface TableCellWrapperProps {
  children: ReactNode;
  className?: string;
  isText?: boolean;
  isNumeric?: boolean;
  onClick?: (e: ChangeEvent<any>) => void;
  onPointerLeave?: (e: ChangeEvent<any>) => void;
}

export const TableCellWrapper: FC<TableCellWrapperProps> = ({
  children,
  className,
  isNumeric = false,
  isText = false,
  onClick,
  onPointerLeave,
}) => {
  if (onClick) {
    return (
      <div
        className={clsx(
          "group/tc flex h-full w-full cursor-pointer items-center pl-3 text-gray-700 hover:text-gray-900",
          className,
        )}
        onClick={onClick}
      >
        <span className="truncate">{children}</span>
        <div className="h-full items-start pl-0.5 pt-1.5">
          <MousePointerIcon className="invisible h-4 w-4 text-blue-600 group-hover/tc:visible" />
        </div>
      </div>
    );
  } else if (isText) {
    return (
      <div
        className={clsx(
          "flex h-full w-full items-center pl-3 text-gray-700",
          className,
          {
            "justify-center text-right": isNumeric,
          },
        )}
      >
        <span className="truncate">{children}</span>
      </div>
    );
  }
  return (
    <div
      className={clsx(
        "flex h-full w-full items-center overflow-hidden pl-3 text-gray-700",
        className,
      )}
      onPointerLeave={onPointerLeave}
    >
      {children}
    </div>
  );
};

interface SortIndicatorIconProps {
  isNumber?: boolean;
  isSorted?: boolean;
  sortDescending?: boolean;
}

const SortIndicatorIcon: FC<SortIndicatorIconProps> = ({
  isSorted,
  sortDescending,
  isNumber = false,
}) => {
  const SortDescending = isNumber ? ArrowDown01Icon : ArrowDownAZIcon;
  const SortAscending = isNumber ? ArrowDown10Icon : ArrowDownZAIcon;

  if (!isSorted || (isSorted && sortDescending)) {
    return <SortDescending className="h-4 w-4" />;
  }
  return <SortAscending className="h-4 w-4" />;
};

const RemoteSorting: FC<TableSortIndicatorsProps> = ({
  tableId,
  column,
  handleFilter,
}) => {
  const columnSorting = useAppStore(
    (state) => state.tableState[tableId].columnSorting,
  );
  const setColumnSorting = useAppStore((state) => state.setColumnSorting);

  if (!column.getCanSort()) return null;

  const sortDirection =
    columnSorting.id === column.id ? columnSorting.direction : undefined;
  const isSorted = sortDirection === -1 || sortDirection === 1;

  const handleClick = () => {
    let currSortDirection: 1 | -1 | undefined = 1;
    const alias = (column.columnDef.meta as any)?.sortBy;
    const useCaseInsensitiveSort = (column.columnDef.meta as any)
      ?.useCaseInsensitiveSort;

    if (sortDirection === 1) {
      currSortDirection = -1;
    } else if (sortDirection === -1) {
      currSortDirection = undefined;
    }

    const updatedSorting = {
      alias,
      useCaseInsensitiveSort,
      id: column.id!,
      direction: currSortDirection,
    };

    setColumnSorting({
      tableId,
      columnSorting: updatedSorting,
    });
    handleFilter?.(updatedSorting);
  };

  return (
    <button
      className={clsx("pl-3", {
        "text-gray-500": isSorted,
        "text-transparent group-hover/th:text-gray-500": !isSorted,
      })}
      onClick={handleClick}
    >
      <SortIndicatorIcon
        isSorted={isSorted}
        sortDescending={sortDirection === 1}
        isNumber={(column.columnDef.meta as any)?.isNumber}
      />
    </button>
  );
};

const LocalSorting = ({ column }: TableSortIndicatorsProps) => {
  const isSorted = column.getIsSorted();
  const canSort = column.getCanSort();

  if (!canSort) return null;

  return (
    <button
      className={clsx("pl-3", {
        "text-gray-500": isSorted,
        "text-transparent group-hover/th:text-gray-500": !isSorted,
      })}
      onClick={column.getToggleSortingHandler()}
    >
      <SortIndicatorIcon
        isSorted={!!isSorted}
        sortDescending={isSorted === "desc"}
        isNumber={(column.columnDef.meta as any)?.isNumber}
      />
    </button>
  );
};

export const TableSortIndicators: FC<TableSortIndicatorsProps> = (props) => {
  if (props.handleFilter) {
    return <RemoteSorting {...props} />;
  }
  return <LocalSorting {...props} />;
};

type IndeterminateCheckboxProps = HTMLProps<HTMLInputElement> & {
  indeterminate?: boolean;
};

const IndeterminateCheckbox: FC<IndeterminateCheckboxProps> = ({
  indeterminate,
  className = "",
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={clsx(
        "amenda-component h-5 w-5 cursor-pointer text-black",
        className,
      )}
      {...rest}
    />
  );
};

interface TableRowSelectorProps {
  table?: Table<any>;
  row?: Row<any>;
  children?: ReactNode;
  tableId: ReactTableKeys;
  handleToggleRowSelection?: (id: string) => void;
  handleToggleRowsSelection?: (ids: string[]) => void;
}

export const TableGrouping: FC<
  Pick<TableRowSelectorProps, "row" | "children">
> = ({ row, children }) => {
  if (row?.getCanExpand()) {
    return (
      <div className="flex items-center">
        <button
          className="cursor-pointer bg-gray-800 p-1 text-white hover:bg-gray-600"
          {...{
            onClick: row.getToggleExpandedHandler(),
            style: { cursor: "pointer" },
          }}
        >
          <ChevronRightIcon
            className={clsx("h-4 w-4", {
              "rotate-90 transform": row.getIsExpanded(),
            })}
          />
        </button>
        {children}
      </div>
    );
  }
  return null;
};

export const TableRowSelector: FC<TableRowSelectorProps> = ({
  table,
  row,
  tableId,
  handleToggleRowSelection,
  handleToggleRowsSelection,
}) => {
  const prevRowSelection = useAppStore(
    (state) => state.tableState[tableId].rowSelection,
  );
  const setRowSelection = useAppStore((state) => state.setRowSelection);

  if (table) {
    const { getIsAllRowsSelected, getIsSomeRowsSelected } = table;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const rows = table.getPreSelectedRowModel().rows;
      const ids: string[] = checked
        ? rows.map((row) => (row.original as any).id)
        : [];

      const rowSelection = (checked ? rows.map((row) => row.index) : []).reduce(
        (acc, i) => {
          return {
            ...acc,
            [i]: checked,
          };
        },
        {},
      );

      handleToggleRowsSelection?.(ids);
      setRowSelection({
        tableId,
        rowSelection,
      });
    };

    return (
      <IndeterminateCheckbox
        checked={getIsAllRowsSelected()}
        indeterminate={getIsSomeRowsSelected()}
        onChange={onChange}
      />
    );
  } else if (row) {
    const { getIsSelected, getCanSelect, getIsSomeSelected } = row;

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const id = (row.original as any).id;
      const rowSelection = {
        ...prevRowSelection,
        [row.index]: checked,
      };
      if (!checked) {
        delete rowSelection[row.index];
      }

      handleToggleRowSelection?.(id);
      setRowSelection({
        tableId,
        rowSelection,
      });
    };

    return (
      <div
        className={clsx({
          "invisible group-hover/tr:visible":
            !getIsSelected() && !getIsSomeSelected(),
        })}
      >
        <IndeterminateCheckbox
          checked={getIsSelected()}
          disabled={!getCanSelect()}
          indeterminate={getIsSomeSelected()}
          onChange={onChange}
        />
      </div>
    );
  }
  return null;
};

interface TablePinColumnProps {
  hidePin?: boolean;
  column: Column<any, any>;
}

export const TablePinColumn: FC<TablePinColumnProps> = ({
  column,
  hidePin = false,
}) => {
  const onClick = () => {
    if (column.getIsPinned()) {
      column.pin(false);
    } else {
      column.pin("left");
    }
  };

  if (!column.getCanPin() || hidePin) return null;
  return (
    <button
      className={clsx("ml-auto pl-3 pr-1", {
        "text-gray-900": column.getIsPinned(),
        "text-transparent group-hover/th:text-gray-500": !column.getIsPinned(),
      })}
      onClick={onClick}
    >
      <PinIcon className="h-4 w-4" />
    </button>
  );
};

const getIsSelected = (columnVisibility: Record<string, boolean>) => {
  const keys = Object.keys(columnVisibility);
  if (keys.length === 0) {
    return true;
  }
  return keys.every((key) => columnVisibility[key]);
};

const getGroupedColumns = (columns: ColumnDef<any>[], shouldSort = false) => {
  const groupedColumns: Record<string, any[]> = {
    ungrouped: [],
  };

  columns.forEach((column) => {
    const meta = column.meta as any;
    if (meta.formId) {
      if (!groupedColumns[meta.formId]) {
        groupedColumns[meta.formId] = [];
      }
      groupedColumns[meta.formId].push(column);
    } else {
      groupedColumns.ungrouped.push(column);
    }
  });

  if (shouldSort) {
    Object.keys(groupedColumns).forEach((key) => {
      const columns = groupedColumns[key];

      groupedColumns[key] = columns.sort((a, b) => {
        const aLabel = (a.meta as any)?.label || a.id;
        const bLabel = (b.meta as any)?.label || b.id;

        return aLabel.localeCompare(bLabel);
      });
    });
  }

  return groupedColumns;
};

const groupFormsById = (forms: FormTab[] = []) => {
  return forms.reduce<Record<string, FormTab>>((acc, form) => {
    return {
      ...acc,
      [form.id]: form,
    };
  }, {});
};

interface ConfigurationDropdownProps {
  tableId: ReactTableKeys;
  columns: ColumnDef<any>[];
}

export const ConfigurationDropdown: FC<ConfigurationDropdownProps> = ({
  tableId,
  columns,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredColumns, setFilteredColumns] = useState<ColumnDef<any>[]>([]);
  const columnVisibility = useAppStore(
    (state) => state.tableState[tableId].columnVisibility,
  );
  const columnOrder = useAppStore(
    (state) => state.tableState[tableId].columnOrder,
  );
  const originalForms = useProjectStore((state) => state.originalForms);
  const setColumnVisibility = useAppStore((state) => state.setColumnVisibility);
  const setColumnOrder = useAppStore((state) => state.setColumnOrder);
  const [isMounted, setIsMounted] = useState(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const formsById = groupFormsById(originalForms);
  const availableColumns = columns.filter(
    (c) =>
      ![
        SpecialColumns.ACTIONS,
        SpecialColumns.SELECT,
        SpecialColumns.GROUPING,
      ].includes(c.id as SpecialColumns),
  );
  const specialColumns = columns.filter((c) =>
    [
      SpecialColumns.ACTIONS,
      SpecialColumns.SELECT,
      SpecialColumns.GROUPING,
    ].includes(c.id as SpecialColumns),
  );
  const isAllSelected = getIsSelected(columnVisibility);
  const activeColumn: any = availableColumns.find((col) => col.id === activeId);
  const activeColumnLabel =
    (activeColumn?.meta as any)?.label || activeColumn?.id;
  const checkedColumns =
    columnOrder.length > 0
      ? columnOrder
          .map((id) => availableColumns.find((col) => col.id === id)!)
          .filter(Boolean)
      : availableColumns.filter((col) =>
          Boolean(
            isNil(columnVisibility[col.id!]) ? true : columnVisibility[col.id!],
          ),
        );
  const checkedColumnIds = checkedColumns.map((col) => col.id!);
  const groupedUncheckedColumns = getGroupedColumns(
    filteredColumns.filter((col) => !checkedColumnIds.includes(col.id!)),
    !searchTerm,
  );

  const handleChange = (id: string, value?: boolean) => {
    setColumnVisibility({
      tableId,
      columnVisibility: {
        ...columnVisibility,
        [id]: Boolean(value),
      },
    });
  };

  const handleSelectAll = (value?: boolean) => {
    let columnVisibility = columns.reduce(
      (acc, { id }) => ({ ...acc, [id!]: Boolean(value) }),
      {},
    );

    specialColumns.forEach((col) => {
      columnVisibility = {
        ...columnVisibility,
        [col.id!]: true,
      };
    });

    setColumnVisibility({
      tableId,
      columnVisibility,
    });
  };

  const handleSearch = (value: string) => {
    let filteredColumns = [...availableColumns];

    if (value) {
      const fuse = new Fuse(availableColumns, {
        threshold: 0.3,
        includeScore: true,
        keys: [
          { name: "id", getFn: (col: ColumnDef<any>) => col.id! },
          {
            name: "label",
            getFn: (col: ColumnDef<any>) => (col.meta as any)?.label || "",
          },
        ],
      });
      const results = fuse.search(value);
      filteredColumns = results.map((res) => res.item);
    }
    setSearchTerm(value);
    setFilteredColumns(filteredColumns);
  };

  const handleDragEnd = (active: Active, over: Over) => {
    const columns: string[] = active.data.current?.sortable?.items ?? [];
    const newIndex = columns.indexOf(over.id as string);
    const oldIndex = columns.indexOf(active.id as string);
    const updatedColumns = arrayMove(columns, oldIndex, newIndex);

    setColumnOrder({
      tableId,
      columnOrder: updatedColumns,
    });
  };

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      setFilteredColumns(availableColumns);
    }
  }, [isMounted, availableColumns]);

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToVerticalAxis]}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        const { active } = event;

        setActiveId(String(active.id));
      }}
      onDragEnd={(event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && isDragValid(active, over)) {
          handleDragEnd(active, over);
        }
      }}
    >
      <Popover as="div" className="relative">
        <PopoverButton
          className={iconButtonTheme({
            variant: "outline",
            size: "xss",
            className: "w-[140px] justify-center bg-gray-50",
          })}
        >
          <Settings2Icon className="h-4 w-4" />{" "}
          <span className="px-2 text-xs">{t("Column Settings")}</span>
        </PopoverButton>
        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <PopoverPanel
            as="div"
            className="absolute right-0 z-50 mt-1 w-80 divide-y divide-gray-100 bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <span className="text-xs">{t("Column Settings")}</span>
            <div className="max-h-[520px] w-full overflow-y-auto overscroll-y-contain">
              <div className="flex-col space-y-1 py-1 pl-2 pr-1">
                {!searchTerm && isAllSelected && (
                  <Link
                    className="w-full text-center text-gray-900"
                    onClick={() => handleSelectAll(false)}
                  >
                    {t("Hide all")}
                  </Link>
                )}
                <SortableContext
                  id="configurationDropdown"
                  items={checkedColumnIds}
                  strategy={verticalListSortingStrategy}
                >
                  <FormBuilderSortDroppable
                    className="space-y-1 border-b border-gray-300"
                    getClassName={(isOver) => {
                      return clsx({
                        "bg-gray-50 ": isOver,
                      });
                    }}
                  >
                    {checkedColumns.map((col) => {
                      const label = (col.meta as any)?.label;

                      return (
                        <FormBuilderSortableItem
                          key={col.id!}
                          id={col.id!}
                          className="w-full cursor-default"
                        >
                          {(isDragging) => (
                            <div
                              className={clsx(
                                "flex w-full items-center overflow-x-hidden truncate rounded-sm text-sm text-gray-900 hover:bg-gray-900 hover:text-white",
                                {
                                  invisible: isDragging,
                                },
                              )}
                            >
                              <IconButtonBase
                                size="xss"
                                className="cursor-grab"
                              >
                                <GripVerticalIcon className="h-4 w-4" />
                              </IconButtonBase>
                              <span className="ml-1.5 truncate">
                                {label || col.id}
                              </span>
                              <IconButtonBase
                                size="xss"
                                className="ml-auto"
                                onClick={() => handleChange(col.id!, false)}
                              >
                                <XIcon className="h-4 w-4" />
                              </IconButtonBase>
                            </div>
                          )}
                        </FormBuilderSortableItem>
                      );
                    })}
                  </FormBuilderSortDroppable>
                </SortableContext>
                {Object.entries(groupedUncheckedColumns).map(
                  ([key, columns]) => {
                    const form = formsById[key];

                    return (
                      <div className="w-full" key={key}>
                        {key !== "ungrouped" && (
                          <span className="text-xs capitalize text-gray-500">
                            {form?.name || key}
                          </span>
                        )}
                        {columns.map((col) => {
                          const label = (col.meta as any)?.label;
                          const checked = Boolean(
                            isNil(columnVisibility[col.id!])
                              ? true
                              : columnVisibility[col.id!],
                          );

                          return (
                            <button
                              key={col.id!}
                              className={clsx(
                                "flex w-full cursor-pointer items-center overflow-x-hidden rounded-sm pl-2 pr-1 text-sm text-gray-900 hover:bg-gray-900 hover:text-white",
                              )}
                              onClick={() => handleChange(col.id!, !checked)}
                            >
                              <span className="my-1 truncate">
                                {label || col.id}
                              </span>
                              <div className="ml-auto">
                                {checked ? (
                                  <EyeOff className="h-4 w-4" />
                                ) : (
                                  <Eye className="h-4 w-4" />
                                )}
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            {!isAllSelected && (
              <div className="sticky bottom-0 z-10 border-b border-gray-300 bg-white">
                <MiniSearchField
                  value={searchTerm}
                  className="border-0"
                  placeholder={t("Search for column") + "..."}
                  onChange={handleSearch}
                />
              </div>
            )}
          </PopoverPanel>
        </Transition>
      </Popover>
      <>
        {createPortal(
          <DragOverlay modifiers={[restrictToVerticalAxis]}>
            {activeColumn && (
              <FormBuilderDragOverlayItem className="w-full cursor-pointer bg-gray-800 px-2 text-sm text-white shadow-lg outline-none">
                <div className="flex w-full items-center space-x-1.5">
                  <IconButtonBase size="xss" className="cursor-grab">
                    <GripVerticalIcon className="h-4 w-4" />
                  </IconButtonBase>
                  <span>{activeColumnLabel}</span>
                </div>
              </FormBuilderDragOverlayItem>
            )}
          </DragOverlay>,
          document.body,
        )}
      </>
    </DndContext>
  );
};

const getCollection = (tableId: ReactTableKeys) => {
  let collection: AllowedCollectionType;

  switch (tableId) {
    case ReactTableKeys.Projects:
      collection = AllowedCollectionType.Projects;
      break;
    case ReactTableKeys.Gallery:
      collection = AllowedCollectionType.Attachments;
      break;
    case ReactTableKeys.Users:
    case ReactTableKeys.UsersFullScreenTable:
      collection = AllowedCollectionType.Users;
      break;
    case ReactTableKeys.ConstructionDetails:
      collection = AllowedCollectionType.ConstructionDetails;
      break;
    case ReactTableKeys.ContactsFullScreenTable:
      collection = AllowedCollectionType.Contacts;
      break;
    default:
      collection = AllowedCollectionType.Projects;
      break;
  }

  return collection;
};

const getSortingProps = (tableId: ReactTableKeys) => {
  switch (tableId) {
    case ReactTableKeys.Projects:
      return {
        defaultSorting: {
          sort: { "case_insensitive_formValues.name": 1 },
        },
        getPrefix: () => "formValues",
      };
    case ReactTableKeys.Gallery:
    case ReactTableKeys.ConstructionDetails:
      return {
        defaultSorting: {
          sort: { createdAt: -1 },
        },
        useCaseInsensitiveSort: false,
        getPrefix: () => "formValues",
      };
    case ReactTableKeys.Users:
    case ReactTableKeys.UsersFullScreenTable:
      return {
        defaultSorting: {
          sort: {
            firstName: 1,
          },
        },
        getPrefix: (colId: string) => {
          return ["firstName", "lastName"].includes(colId)
            ? undefined
            : "userDetails";
        },
      };
    case ReactTableKeys.ContactsFullScreenTable:
      return {
        defaultSorting: {
          sort: { "case_insensitive_formValues.fullName": 1 },
        },
        getPrefix: (colId: string) => {
          return ["firstName", "lastName"].includes(colId)
            ? undefined
            : "contactDetails";
        },
      };
    default:
      return {};
  }
};

const getColumnFullPath = ({
  path,
  tableId,
  columnId,
}: {
  tableId: ReactTableKeys;
  path: string;
  columnId: string;
}) => {
  switch (tableId) {
    case ReactTableKeys.Users:
    case ReactTableKeys.UsersFullScreenTable:
      return ["firstName", "lastName"].includes(columnId)
        ? path
        : `userDetails.${path}`;

    case ReactTableKeys.ContactsFullScreenTable:
      return ["firstName", "lastName"].includes(columnId)
        ? path
        : `contactDetails.${path}`;
    default:
      return `formValues.${path}`;
  }
};

interface DownloadButtonProps {
  tableId: ReactTableKeys;
  columns: Column<any, any>[];
  disabled: boolean;
  downloadProps?: Record<string, any>;
}

export const DownloadButton: FC<DownloadButtonProps> = ({
  tableId,
  columns,
  disabled,
  downloadProps = {},
}) => {
  const { t } = useTranslation();
  const { loading, downloadFile } = useDownload();
  const columnSorting = useAppStore(
    (state) => state.tableState[tableId].columnSorting,
  );

  const handleDownload = async () => {
    const collection = getCollection(tableId);
    const sortingProps = generateColumnSorting({
      ...(columnSorting || {}),
      ...getSortingProps(tableId),
      id: columnSorting?.id || "",
    });
    const columnOrder = columns
      .filter((c) => c.getIsVisible())
      .filter((c) => Boolean((c.columnDef as any)?.meta?.formId))
      .map((c) => {
        const path = (c.columnDef as any)?.meta?.sortBy || c.id;
        const label = (c.columnDef as any)?.meta?.label || c.id;
        const formId = (c.columnDef as any)?.meta?.formId;
        const fullPath = getColumnFullPath({ tableId, path, columnId: c.id });

        return {
          label,
          formId,
          componentId: c.id,
          path: fullPath,
        };
      });
    const { ids, filters = {} } = downloadProps;
    let input = {
      ...sortingProps,
      ids,
      collection,
      columns: columnOrder,
      limit: ids?.length,
      filter: {
        ...filters,
        isDeleted: false,
      },
      outputFormat: AllowedDownloadOutput.Csv,
    };
    input = sanitizeNestedData(input);

    const link = await downloadFile(input);
    if (link) {
      openUrlInNewTab(link);
    }
  };

  return (
    <Button
      size="xss"
      loading={loading}
      disabled={disabled}
      onClick={handleDownload}
    >
      <span>{t("Download")}</span>
      <CloudDownloadIcon className="ml-1 size-4" />
    </Button>
  );
};
