import {
  ButtonWithDropdown,
  Counter,
  HelperMessage,
  IconButton,
  Tooltip,
} from "@amenda-components/App";
import { CalculatorIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { CostEstimateBody, CostEstimateCheckbox } from "./CostEstimateBody";
import {
  CostEstimateColumns,
  CostEstimationOptionType,
  QuantityEstimateColumns,
  costEstimateHeaders,
  getCostEstimateOptions,
  getCostGroupQuantityComponents,
  getMissingQuantities,
  quantityEstimateHeaders,
} from "./common";
import { FC, memo, useState } from "react";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { MainContainer, SidebarContainer } from "@amenda-components/Shared";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { devConsole, formatDate, formatQuarter } from "@amenda-utils";
import { isEmpty, isNil } from "lodash";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { Control } from "react-hook-form";
import { CostEstimationForm } from "./CostEstimationForm";
import { NestedPageComponentProps } from "@amenda-types";
import { QuantityEstimateBody } from "./QuantityEstimateBody";
import clsx from "clsx";
import { getComponentByCode } from "@amenda-components/CostGroup/common";
import { useGetBKIValue } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import xlsx from "json-as-xlsx";

interface Props {
  control: Control<any>;
  costGroupCostFormName?: string;
  costGroupQuantityFormName?: string;
  groupedCostGroupComponents?: NestedPageComponentProps[];
  groupedQuantityGroupComponents?: NestedPageComponentProps[];
  costGroupCostComponents?: any[];
  handleClose: () => void;
  handleOpenModal: () => void;
  handleOpenExistingModal: () => void;
}

export const CostEstimationModalDesktopSidebar: FC<Pick<Props, "control">> =
  memo(({ control }) => {
    const { t } = useTranslation();

    return (
      <SidebarContainer className="w-96 border-r">
        <div className="border-b border-gray-200 p-1">
          <p className="p-4 pb-4">{t("Cost Estimate DIN 276")}</p>
        </div>
        <CostEstimationForm control={control} />
      </SidebarContainer>
    );
  });

interface TabPanelWrapperProps {
  control: Control<any>;
  components?: any[];
  columns: any[];
  RowBody: any;
  RowSelector?: any;
  globalData?: any;
}

const TabPanelWrapper: FC<TabPanelWrapperProps> = ({
  control,
  components,
  columns,
  ...rest
}) => {
  if (isNil(components) || isEmpty(components)) {
    return (
      <HelperMessage
        message="Restricted Form"
        helpText="You don't have permissions to view this form"
        Icon={CalculatorIcon}
      />
    );
  }

  return (
    <ComponentsTreeView
      readOnly={true}
      control={control}
      components={components}
      columns={columns}
      className="max-h-[80vh] pb-4"
      columnClassName="lg:w-44 w-40"
      searchPlaceholder="Kostengruppe filtern..."
      rowClassName="group/treeRow hover:bg-gray-100 border-b border-transparent hover:border-gray-200"
      {...rest}
    />
  );
};

const CostEstimateTabs: FC<
  Pick<
    Props,
    | "control"
    | "costGroupCostComponents"
    | "groupedCostGroupComponents"
    | "groupedQuantityGroupComponents"
    | "costGroupCostFormName"
    | "costGroupQuantityFormName"
  >
> = ({
  control,
  costGroupCostComponents,
  groupedCostGroupComponents,
  groupedQuantityGroupComponents,
  costGroupCostFormName,
  costGroupQuantityFormName,
}) => {
  const { t } = useTranslation();
  const costEstimationModalValues = useProjectStore(
    (state) => state.costEstimationModalValues,
  );
  const setCostEstimateTab = useProjectStore(
    (state) => state.setCostEstimateTab,
  );

  const costGroupQuantityComponents = getCostGroupQuantityComponents(
    costEstimationModalValues,
    groupedQuantityGroupComponents,
  );
  const { componentByCode } = getComponentByCode(costGroupCostComponents ?? []);
  const missingQuantities = getMissingQuantities({
    componentByCode,
    groupedCostGroupComponents,
    costGroupQuantityComponents,
    formValues: costEstimationModalValues,
  });

  return (
    <TabGroup defaultIndex={0} onChange={setCostEstimateTab}>
      <TabList className="amenda-tab-list sticky top-0 z-40 w-full overflow-y-auto overscroll-y-contain border-0 bg-white px-2 pt-4">
        <Tab
          className={({ selected }) =>
            clsx(
              "flex items-center space-x-1 whitespace-nowrap px-3 py-2 text-sm focus:outline-none",
              {
                "bg-gray-900 text-white": selected,
                "text-gray-900 hover:bg-gray-900 hover:text-white": !selected,
              },
            )
          }
        >
          {costGroupCostFormName && t(costGroupCostFormName)}
        </Tab>
        <Tab
          className={({ selected }) =>
            clsx(
              "relative flex items-center space-x-1 whitespace-nowrap px-3 py-2 text-sm focus:outline-none",
              {
                "bg-gray-900 text-white": selected,
                "text-gray-900 hover:bg-gray-900 hover:text-white": !selected,
                hidden: isEmpty(costEstimationModalValues),
              },
            )
          }
        >
          {costGroupQuantityFormName && t(costGroupQuantityFormName)}
          <Counter
            count={missingQuantities.length}
            className="absolute -right-2 -top-2 h-5 w-5"
          />
        </Tab>
      </TabList>
      <TabPanels className="w-full pt-4">
        <TabPanel>
          <TabPanelWrapper
            control={control}
            columns={costEstimateHeaders}
            components={groupedCostGroupComponents}
            RowBody={CostEstimateBody}
            RowSelector={CostEstimateCheckbox}
          />
        </TabPanel>
        <TabPanel>
          <TabPanelWrapper
            control={control}
            columns={quantityEstimateHeaders}
            components={costGroupQuantityComponents}
            RowBody={QuantityEstimateBody}
            globalData={{
              missingQuantities,
            }}
          />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  );
};

export const CostEstimationModalMainContent: FC<Props> = memo(
  ({ handleClose, handleOpenModal, handleOpenExistingModal, ...rest }) => {
    const { t } = useTranslation();
    const [optionIndex, setOptionIndex] = useState(0);
    const [isExporting, setIsExporting] = useState(false);
    const costEstimateValues = useProjectStore(
      (state) => state.costEstimateValues,
    );
    const permissions = useSettingsStore(
      (state) => state.currentUserSystemRole?.permissions || {},
    );
    const costEstimationModalValues = useProjectStore(
      (state) => state.costEstimationModalValues,
    );
    const { getBKIValue } = useGetBKIValue();

    const projectsGeneralPermissions = getFromGeneralPermissions(
      permissions,
      GeneralPermissionKeys.Projects,
    );
    const options = getCostEstimateOptions(projectsGeneralPermissions);

    const handleExcelExport = async () => {
      try {
        setIsExporting(true);

        let bkiValue;
        const { groupedQuantityGroupComponents, costGroupCostComponents } =
          rest;
        const { componentByCode } = getComponentByCode(
          costGroupCostComponents ?? [],
        );
        const costGroupQuantityComponents = getCostGroupQuantityComponents(
          costEstimationModalValues,
          groupedQuantityGroupComponents,
        );

        if (costEstimationModalValues?.considerationDate) {
          bkiValue = await getBKIValue({
            skipStorage: true,
            date: costEstimationModalValues?.considerationDate,
          });
        }
        const dateTime = new Date().toISOString();
        const bkiQuarter =
          costEstimationModalValues?.considerationDate &&
          formatQuarter(costEstimationModalValues.considerationDate);
        const fileName = [
          "Kostenueberschlag",
          formatDate(dateTime),
          costEstimationModalValues?.depth,
          bkiQuarter,
          bkiValue,
          costEstimationModalValues?.region?.city,
        ]
          .filter(Boolean)
          .join("_");
        const availableQuantityIds = Object.keys(
          costEstimationModalValues?.considerationValues ?? {},
        );
        const costEstimateQuantityContent = costGroupQuantityComponents
          .filter((c) => availableQuantityIds.includes(c.id))
          .map((c) => {
            const quantity = isFinite(
              costEstimationModalValues?.considerationValues?.[c.id],
            )
              ? Number(costEstimationModalValues?.considerationValues?.[c.id])
              : null;

            return {
              [QuantityEstimateColumns.Placeholder]:
                c.properties?.label ?? c.id,
              [QuantityEstimateColumns.Quantity]: quantity,
            };
          });
        const costEstimateCostContent = Object.keys(
          costEstimateValues ?? {},
        ).map((key) => {
          const values = costEstimateValues[key];
          const hits = isFinite(values.costGroupCount)
            ? `${values.costGroupCount}/${values.totalProjects}`
            : "";
          const unitPrice = isFinite(values.costGroupAverage)
            ? Number(values.costGroupAverage)
            : null;
          const averagePrice = isFinite(values.costGroupTotal)
            ? Number(values.costGroupTotal)
            : null;

          return {
            label: componentByCode[key]?.properties?.label ?? key,
            [CostEstimateColumns.UnitPrice]: unitPrice,
            [CostEstimateColumns.AveragePrice]: averagePrice,
            [CostEstimateColumns.TotalCost]: values.percentageCost,
            [CostEstimateColumns.Hits]: hits,
          };
        });

        const settings = {
          fileName,
          extraLength: 3, // A bigger number means that columns will be wider
          writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
          writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
          RTL: false,
        };
        const data = [
          {
            content: costEstimateCostContent,
            columns: [
              {
                id: "label",
                label: t("Cost"),
              },
              ...costEstimateHeaders,
            ].map((header) => ({
              value: header.id,
              label: t(header.label),
            })),
          },
          {
            content: costEstimateQuantityContent,
            columns: [
              {
                value: QuantityEstimateColumns.Placeholder,
                label: "",
              },
              {
                value: QuantityEstimateColumns.Quantity,
                label: t("Quantity"),
              },
            ],
          },
        ];

        xlsx(data, settings);
      } catch (err) {
        devConsole?.error(
          "[CostEstimationModalMainContent] handleExcelExport error:",
          err,
        );
      } finally {
        setIsExporting(false);
      }
    };

    const handleClick = (value: string) => {
      const foundIndex = options.findIndex((option) => option.value === value);
      setOptionIndex(foundIndex);

      if (value === CostEstimationOptionType.New) {
        return handleOpenModal();
      } else if (value === CostEstimationOptionType.Existing) {
        return handleOpenExistingModal();
      }
      return handleExcelExport();
    };

    return (
      <MainContainer className="relative pt-4">
        <div className="flex justify-end border-b border-gray-200 px-2 pb-4">
          <IconButton
            Icon={XMarkIcon}
            label={t("Close modal")}
            onClick={handleClose}
          />
        </div>
        <CostEstimateTabs {...rest} />
        <div className="absolute bottom-0 z-30 flex w-full justify-end border-t border-gray-200 bg-white p-2">
          <Tooltip
            id="cost_estimate_new_project"
            message={
              isEmpty(costEstimateValues)
                ? "Please run cost estimation before proceeding."
                : undefined
            }
          >
            <ButtonWithDropdown
              loading={isExporting}
              defaultIndex={optionIndex}
              disabled={isExporting || isEmpty(costEstimateValues)}
              options={options}
              onClick={handleClick}
            />
          </Tooltip>
        </div>
      </MainContainer>
    );
  },
  (prevProps, nextProps) => {
    if (
      JSON.stringify(prevProps.groupedCostGroupComponents) !==
      JSON.stringify(nextProps.groupedCostGroupComponents)
    ) {
      return false;
    } else if (
      JSON.stringify(prevProps.groupedQuantityGroupComponents) !==
      JSON.stringify(nextProps.groupedQuantityGroupComponents)
    ) {
      return false;
    }
    return true;
  },
);
